import MENTERI_BESAR_PAHANG from '../images/MENTERI_BESAR_PAHANG.jpg'
import SETIAUSAHA_KERAJAAN_NEGERI_PAHANG from '../images/SETIAUSAHA_KERAJAAN_NEGERI_PAHANG.jpg'
import PENASIHAT_UNDANG_NEGERI_PAHANG from '../images/PENASIHAT_UNDANG-UNDANG_NEGERI_PAHANG.jpeg'
import PEGAWAI_KEWANGAN_NEGERI_PAHANG from '../images/PEGAWAI_KEWANGAN_NEGERI_PAHANG.jpeg'
import PENGARAH_TANAH_GALIAN_NEGERI_PAHANG from '../images/PENGARAH_TANAH_GALIAN_NEGERI_PAHANG.jpeg'
import EXCO_NEGERI_PAHANG from '../images/EXCO_NEGERI_PAHANG.jpeg'
import PEGAWAI_PELABURAN_NEGERI_PAHANG from '../images/PEGAWAI_PELABURAN_NEGERI_PAHANG.jpeg'
import PESARA_KERAJAAN from '../images/PESARA_KERAJAAN.jpeg'

export const boardOfDirectors = [
  {
    name: 'YAB. Dato’ Sri Haji Wan Rosdy Bin Wan Ismail',
    title: 'PENGERUSI',
    text: 'MENTERI BESAR PAHANG',
    image: MENTERI_BESAR_PAHANG,
    delay: 300,
  },
  {
    name: 'YB. Dato’ Sri Sallehuddin Bin Ishak',
    title: 'PENGARAH',
    text: 'SETIAUSAHA KERAJAAN NEGERI PAHANG',
    image: SETIAUSAHA_KERAJAAN_NEGERI_PAHANG,
    delay: 600,
  },
  {
    name: 'YB. Dato’ Saiful Edris Bin Zainuddin',
    title: 'PENGARAH',
    text: 'PENASIHAT UNDANG-UNDANG NEGERI PAHANG',
    image: PENASIHAT_UNDANG_NEGERI_PAHANG,
    delay: 900,
  },
  {
    name: 'YB.  Dato’ Indera Nazri Bin Abu Bakar',
    title: 'PENGARAH',
    text: 'PEGAWAI KEWANGAN NEGERI PAHANG',
    image: PEGAWAI_KEWANGAN_NEGERI_PAHANG,
    delay: 1200,
  },
  {
    name: 'YH. Dato’ Haji Fadzilla Bin Haji Salleh',
    title: 'PENGARAH',
    text: 'PENGARAH TANAH GALIAN NEGERI PAHANG',
    image: PENGARAH_TANAH_GALIAN_NEGERI_PAHANG,
    delay: 300,
  },
  {
    name: 'YB. Dato’ Haji Mohammad Fakhruddin Bin Mohd Ariff',
    title: 'PENGARAH',
    text: 'EXCO NEGERI PAHANG',
    image: EXCO_NEGERI_PAHANG,
    delay: 600,
  },
  {
    name: 'YH. Dato’ Kamarul ‘Arifin Bin Ahmad @ Abd Rahman',
    title: 'PENGARAH',
    text: 'PEGAWAI PELABURAN NEGERI PAHANG',
    image: PEGAWAI_PELABURAN_NEGERI_PAHANG,
    delay: 900,
  },
  {
    name: 'Tuan Mohamed Anuar Bin Mohamed Zainun',
    title: 'PENGARAH',
    text: 'PESARA KERAJAAN',
    image: PESARA_KERAJAAN,
    delay: 1200,
  },
]
