import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'

import { Button, Col, Container, Form, Row, Stack } from 'react-bootstrap'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { FaFax } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'

import Title from 'components/Title/index'
import GoogleMaps from 'components/GoogleMaps/index'

import './style.scss'

const ContactUs = () => {
  return (
    <section id='hubungi' className='contact-us'>
      <Container>
        <div className='get-in-touch-wrapper'>
          <Title text='Hubungi Kami' />
          <div className='d-flex justify-content-center pb-5'>
            <div className='shadow p-3 w-100'>
              <Row>
                <Col sm={12} md={6} className='p-5'>
                  <div className='get-in-touch'>
                    <h3 className='pb-4'>Hubungi</h3>
                    <div className='d-flex pb-3 info'>
                      <BsFillTelephoneFill size={20} color='red' />
                      <h6 className='ps-4'>+09-531 5789</h6>
                    </div>
                    <div className='d-flex pb-3 info'>
                      <FaFax size={20} color='red' />
                      <h6 className='ps-4'>+09-505 1472</h6>
                    </div>
                    <div className='d-flex  mb-5 info'>
                      <MdEmail size={20} color='red' />
                      <h6 className='ps-4'>official@pahangcorp.com.my</h6>
                    </div>
                  </div>
                  <div
                    className='p-3 mb-5'
                    style={{ backgroundColor: '#EDEDED' }}
                  >
                    <h5 className='pb-3'>Waktu Operasi</h5>
                    <Stack>
                      <Stack
                        direction='horizontal'
                        gap={3}
                        className='pe-2 pb-2'
                      >
                        <div>Isnin - Jumaat</div>
                        <div>: 8:00 pgi - 5:00 ptg</div>
                      </Stack>
                      <Stack
                        direction='horizontal'
                        gap={3}
                        className='pe-2 pb-2'
                      >
                        <div>Sabtu & Ahad</div>
                        <div style={{ paddingLeft: '4px' }}>: Tutup</div>
                      </Stack>
                    </Stack>
                  </div>
                  <div class='d-grid'>
                    <Button size='lg' style={{ borderRadius: 'unset' }}>
                      Hubungi
                    </Button>
                  </div>
                </Col>
                <Col md={6}>
                  <GoogleMaps />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* AnyQuestion */}
      </Container>
      <ParallaxBanner
        layers={[
          {
            image:
              'https://images.unsplash.com/photo-1484176141566-3674cda218f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1774&q=80',
            speed: -20,
          },
        ]}
        className='aspect-[2/1]'
        style={{ height: '420px' }}
      >
        <div
          className='d-flex justify-content-center  position-absolute '
          style={{
            width: '100%',
            padding: '5rem',
            height: '18.1rem',
          }}
        >
          <div className='text-center p-3 px-5'>
            <h3 className='pb-3 text-white'>Ada sebarang pertanyaan?</h3>
            {/* <p className='pb-3 text-white'>
              Proin eget tortor risus. Proin eget tortor risus.
            </p> */}
            <Container>
              <Form className='question-wrapper'>
                <Row className='justify-content-center'>
                  {/* <Col sm={12}> */}
                  <Form.Control
                    className='mb-2'
                    type='email'
                    placeholder='Your email'
                    size='lg'
                  />
                  {/* </Col> */}
                  {/* <Col sm={12}> */}
                  <Form.Control
                    className='mb-2'
                    placeholder='Message'
                    size='lg'
                    as='textarea'
                    rows={3}
                  />
                  {/* </Col> */}
                  {/* <Col sm={12}> */}
                  <Button
                    // className='ms-2'
                    variant='primary'
                    type='submit'
                    size='lg'
                    style={{ borderRadius: 'unset' }}
                  >
                    Hantar
                  </Button>
                  {/* </Col> */}
                </Row>
              </Form>
            </Container>
          </div>
        </div>
      </ParallaxBanner>
    </section>
  )
}

export default ContactUs
