import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

import { SEO } from 'components/SEO/index'
import Layout from 'components/layouts/Layout'
import Banner from 'containers/Banner'
import AboutUs from 'containers/AboutUs'
import CoreActivities from 'containers/CoreActivities/index'
import Members from 'containers/Members/index'
import NewsMedia from 'containers/News&Media/index'
import Careers from 'containers/Careers/index'
import ContactUs from 'containers/ContactUs/index'

const IndexPage = () => (
  <ParallaxProvider>
    <Layout>
      <Banner />
      <AboutUs />
      <CoreActivities />
      <Members />
      <NewsMedia />
      <Careers />
      <ContactUs />
    </Layout>
  </ParallaxProvider>
)

export default IndexPage

export const Head = () => <SEO />
