import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'

import Title from 'components/Title'

import './style.scss'

const Members = () => {
  return (
    <Container className='members'>
      <Title text='Anak Syarikat Pahang Corp' />
      <Row className='pb-5'>
        <Col
          xs={12}
          sm={6}
          md={4}
          className='mb-2'
          style={{ textAlign: 'center' }}
        >
          <StaticImage
            src='../../assets/logoCompany/koridor_utiliti_pahang.png'
            alt='Pahang Corp'
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4}
          className='mb-2'
          style={{ textAlign: 'center' }}
        >
          <StaticImage
            src='../../assets/logoCompany/kup_land.png'
            alt='KUPLAND'
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4}
          className='mb-2'
          style={{ textAlign: 'center' }}
        >
          <StaticImage src='../../assets/logoCompany/oraco.png' alt='ORACO' />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4}
          className='mb-2'
          style={{ textAlign: 'center' }}
        >
          <StaticImage
            src='../../assets/logoCompany/pahang_go.png'
            alt='Pahang Corp'
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4}
          className='mb-2'
          style={{ textAlign: 'center' }}
        >
          <StaticImage
            src='../../assets/logoCompany/pahang_water.png'
            alt='KUPLAND'
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={4}
          className='mb-2'
          style={{ textAlign: 'center' }}
        >
          <StaticImage
            src='../../assets/logoCompany/pc_management_services.png'
            alt='ORACO'
          />
        </Col>
      </Row>
      <div className='d-flex justify-content-center mb-5'>
        <hr class='border  opacity-100' style={{ width: '50rem' }} />
      </div>
    </Container>
  )
}

export default Members
