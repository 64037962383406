import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import './footer.scss'

const Footer = () => {
  return (
    <div className='footer-wrapper'>
      <div className='footer'>
        <Container className='py-5'>
          <Row>
            <Col xs={12} md={5} className='logo-wrapper'>
              {/* logo */}
              <div className='pb-4'>
                <StaticImage
                  className='logo'
                  src='../../assets/logoCompany/logo-footer.png'
                  alt='logo'
                  // width={150}
                />
              </div>
              <div>
                <h6 style={{ fontSize: '14px' }}>
                  Incorporated On The 21st March 2019 Wholly Owned (100%)
                  Subsidiary Of Pahang State Government Group Core Activities
                </h6>
              </div>
            </Col>
            <Col xs={12} md={7}>
              <Stack direction='horizontal' gap={2}>
                <Stack className='pb-2' gap={2} style={{ color: 'white' }}>
                  <h5 className='pb-3'>Tentang Kami</h5>
                  <AnchorLink
                    className='dropdown-item'
                    to='/#mengenai'
                    title='Mengenai Pahang Corp'
                  />
                  <AnchorLink
                    className='dropdown-item'
                    to='/#visi-misi'
                    title='Visi & Misi'
                  />
                  {/* <AnchorLink
                    className='dropdown-item'
                    to='/#maklumat-korporat'
                    title='Maklumat Korporat'
                  /> */}
                  <AnchorLink
                    className='dropdown-item'
                    to='/#lembaga-pengarah'
                    title='Lembaga Pengarah'
                  />
                </Stack>
                <Stack gap={2} style={{ color: 'white' }}>
                  <AnchorLink className='dropdown-item' to='/#mengenai'>
                    <h5 className='pb-3'>Aktiviti Perniagaan</h5>
                  </AnchorLink>
                </Stack>
                <Stack gap={2} style={{ color: 'white' }}>
                  <h5 className='pb-3'>Berita</h5>
                </Stack>
                <Stack gap={2} style={{ color: 'white' }}>
                  <h5 className='pb-3'>Hubungi Kami</h5>
                </Stack>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
      {/* footer All Rights Reserved */}
      <section
        className='all-right-reserved py-3'
        style={{ backgroundColor: '#C6C6C6' }}
      >
        <Container className='sec-wrapper'>
          <Row className='w-100'>
            <Col>
              <Stack direction='horizontal' gap={2} className='sec-1'>
                <span>
                  Copyright © <b>Pahang Corparation Sdn. Bhd.</b>
                </span>
                <div className='vr d-none d-xl-block' />
                <span>All Rights Reserved</span>
              </Stack>
            </Col>
            {/* <Col sm={12} lg={6}>
              <Stack direction='horizontal' gap={3} className='sec-2'>
                <span>Personal Data Protection</span>
                <span>Anti Bribery Policy</span>
                <span>Whistleblowing Policy</span>
              </Stack>
            </Col> */}
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Footer
