import React from 'react'
import { Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const navArray = [
  {
    title: 'Tentang Kami',
    dropdown: [
      {
        title: 'Mengenai Pahang Corp',
        href: '/#mengenai',
      },
      {
        title: 'Visi & Misi',
        href: '/#visi-misi',
      },
      {
        title: 'Lembaga Pengarah',
        href: '/#lembaga-pengarah',
      },
    ],
  },
  {
    title: 'Aktiviti Perniagaan',
    href: '/#aktiviti-perniagaan',
  },
  {
    title: 'Berita',
    href: '/#berita',
  },
  {
    title: 'Hubungi Kami',
    href: '/#hubungi',
  },
]

const Header = () => {
  const handleCollapse = () => {
    console.log('handleCollapse')
    var nav = document.getElementById('navbarNav')
    var btn = document.getElementById('navbarBtn')
    nav.classList.remove('show')
    btn.classList.add('collapsed')
  }

  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      sticky='top'
      className='px-4'
      style={{
        backgroundColor: '#C6C6C6',
        position: 'fixed',
        transform: 'translate3d(0px, 0px, 0px)',
        width: '100%',
      }}
    >
      <Navbar.Brand className='logo'>
        <Nav.Link className='nav-link' offset={70} href='#banner'>
          <StaticImage
            src={'../../assets/logoCompany/Pahang_Corp.png'}
            alt='logo'
            height={45}
          />
        </Nav.Link>
      </Navbar.Brand>
      <Navbar.Toggle id='navbarBtn' />
      <Navbar.Collapse id='navbarNav' className='justify-content-end'>
        <Nav>
          {navArray.map((navData) => {
            const { title, href, dropdown } = navData
            return dropdown ? (
              <NavDropdown offset={70} title={title} id={title}>
                {dropdown.map((dropdownData) => (
                  <AnchorLink
                    className='dropdown-item'
                    to={dropdownData.href}
                    title={dropdownData.title}
                    onAnchorLinkClick={handleCollapse}
                  />
                ))}
              </NavDropdown>
            ) : (
              <AnchorLink
                offset={70}
                className='nav-link'
                to={href}
                title={title}
                onAnchorLinkClick={handleCollapse}
              />
            )
          })}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header
