import React from 'react'

import { GoMail } from 'react-icons/go'
import PEMBANGUNAN_PENGURUSAN_HARTANAH from '../images/PEMBANGUNAN_&_PENGURUSAN_HARTANAH.png'
import PENGURUSAN_SUMBER_AIR_MENTAH from '../images/PENGURUSAN_SUMBER_AIR_MENTAH.png'
import PENGURUSAN_TENAGA from '../images/PENGURUSAN_TENAGA.png'
import PENGURUSAN_KOPORAT from '../images/PENGURUSAN_KOPORAT.png'
import PENGURUSAN_UTILITI from '../images/PENGURUSAN_UTILITI.png'
import PENDIGITALAN_EKONOMI from '../images/PENDIGITALAN_EKONOMI.png'

export const coreActivities = [
  {
    title: 'PEMBANGUNAN & PENGURUSAN HARTANAH',
    icon: PEMBANGUNAN_PENGURUSAN_HARTANAH,
    delay: 300,
  },
  {
    title: 'PENGURUSAN SUMBER AIR MENTAH',
    icon: PENGURUSAN_SUMBER_AIR_MENTAH,
    delay: 600,
  },
  {
    title: 'PENGURUSAN TENAGA',
    icon: PENGURUSAN_TENAGA,
    delay: 900,
  },
  {
    title: 'PENGURUSAN KOPORAT',
    icon: PENGURUSAN_KOPORAT,
    delay: 300,
  },
  {
    title: 'PENGURUSAN UTILITI',
    icon: PENGURUSAN_UTILITI,
    delay: 600,
  },
  {
    title: 'PENDIGITALAN EKONOMI',
    icon: PENDIGITALAN_EKONOMI,
    delay: 900,
  },
]
