import React from 'react'

import PropTypes from 'prop-types'

import Divider from '../../assets/shape/Divider.svg' //src/assets/shape/Divider.svg

import './style.scss'

const Title = ({ text, ...props }) => {
  return (
    <div className='title-wrapper' {...props}>
      <h3 className='heading-title pe-3'>{text}</h3>
      <Divider />
    </div>
  )
}

export default Title

// Setting default values for the props of Title
Title.defaultProps = {
  text: 'Title',
}

// Typechecking props for the Title
Title.propTypes = {
  text: PropTypes.string.isRequired,
}
