import React from 'react'
import { useSiteMetadata } from '../../hook/use-site-metadata'
// import icon from '../../assets/favicon_io/Pahang_Corp.png'

export const SEO = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name='description' content={seo.description} />
      <meta name='image' content={seo.image} />
      <meta property='og:image' content={seo.image} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:url' content={seo.url} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={seo.image} />
      <meta name='twitter:image:alt' content={seo.title} />
      <meta name='twitter:creator' content={seo.twitterUsername} />
      <meta name='twitter:site' content={seo.twitterUsername} />
      {/* <link rel='icon' href='../../assets/favicon_io/Pahang_Corp.png' /> */}
      {children}
    </>
  )
}
