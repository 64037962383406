import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import './style.scss'

const Hexagon = ({ no, icon, title, text }) => {
  return (
    <div className='hexagon position-relative'>
      <StaticImage
        src='../../assets/shape/HexagonLayerGray.svg' //src/assets/shape/HexagonLayerGray.svg
        alt='Hexagon'
        width={320}
      />
      {/* icon */}
      {/* <div
        className='position-absolute'
        style={{
          borderRadius: '100%',
          padding: '10px',
          backgroundColor: 'antiquewhite',
          top: '3rem',
          left: '2rem',
        }}
      >
        <img src={icon} width={42} height={42} style={{ objectFit: 'cover' }} />
      </div> */}
      <div
        className='position-absolute'
        style={{
          top: '1.5rem',
          left: '5rem',
          textAlign: 'center',
          maxWidth: '10.4rem',
          height: '14.5rem',
        }}
      >
        <h1
          className='fw-bold'
          style={{ fontSize: '3.5rem', marginTop: '0.5rem' }}
        >
          {no}
        </h1>
        <h5>{title}</h5>
        <img src={icon} width={48} height={48} style={{ objectFit: 'cover' }} />
        {/* <p style={{ fontSize: '14px' }}>{text}</p> */}
      </div>
    </div>
  )
}

export default Hexagon
