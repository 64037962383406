export const news = [
  {
    title: `SIDANG DEWAN: LIMA ANAK
    SYARIKAT PAHANG CORP
    BANTU LANCAR PENGURUSAN
    NEGERI`,
    date: '01/04/2022',
    desc: `Lima buah anak syarikat Pahang Corp iaitu Pahang Corporate
    Management Services Sdn Bhd (PCMS), Pahang Water and Energy Resources Sdn Bhd (PWE),
    Pahang Orang Asli Corporation Sdn Bh (ORACO), Koridor Utiliti Pahang Sdn Bhd (KOP) dan
    Pahang Go Sdn Bh (PGSB) memainkan peranan tersendiri dalam menyumbang kepada
    Kerajaan Negeri.`,
    image:
      'https://pahangmedia.my/wp-content/uploads/2022/04/WhatsApp-Image-2022-04-01-at-11.37.15-AM.jpeg',
    link: 'https://pahangmedia.my/sidang-dewan-lima-anak-syarikat-pahang-corp-bantu-lancar-pengurusan-negeri/',
  },
  {
    title: `Lapangan Terbang Antarabangsa Kuantan di Gebeng, mula operasi 2026`,
    date: '06/07/2022',
    desc: `Pembinaan Lapangan Terbang Antarabangsa Kuantan di Gebeng di sini, dijadual bermula tahun depan dengan kos RM2 bilion dan beroperasi pada 2026, kata Menteri Besar, Datuk Seri Wan Rosdy Wan Ismail.
    Beliau berkata, pembinaan lapangan terbang itu juga dibuat dengan rancangan pembangunan bercampur bandar aeroangkasa membabitkan kawasan seluas 5,042 hektar, yang disifatkan sebagai pemangkin pertumbuhan ekonomi Pahang.`,
    image: 'https://assets.bharian.com.my/images/articles/wan_1657110626.jpg',
    link: 'https://www.bharian.com.my/berita/wilayah/2022/07/973850/lapangan-terbang-antarabangsa-kuantan-di-gebeng-mula-operasi-2026',
  },
  {
    title: `Pahang sentiasa semak dan imbang GLC`,
    date: '01/04/2022',
    desc: `Kerajaan Pahang memberi jaminan semak dan imbang sentiasa boleh dilakukan terhadap syarikat berkaitan kerajaan (GLC) Pahang sekiranya syarikat terlibat tidak diaudit Jabatan Audit Negara.
    .Menteri Besar Pahang, Datuk Seri Wan Rosdy Wan Ismail berkata, kerja-kerja audit boleh dilaksanakan sama ada secara dalaman atau audit luar bagi memastikan wujudnya akauntabiliti dan ketelusan.    `,
    image:
      'https://www.sinarharian.com.my/uploads/images/2022/04/01/1647029.jpeg',
    link: 'https://www.sinarharian.com.my/article/195812/edisi/pahang-sentiasa-semak-dan-imbang-glc',
  },
]
