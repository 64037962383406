import React from 'react'
import GoogleMapReact from 'google-map-react'

const GoogleMaps = ({ latitude, longitude }) => {
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: 3.816462903165087, lng: 103.32662199688538 },
      map,
      title: 'Tourism Pahang',
    })
    return marker
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAcNfgGqLP1BNjvvwbjASiy-Ia_sOqnfyM' }}
        defaultCenter={{ lat: 3.816462903165087, lng: 103.32662199688538 }}
        defaultZoom={17}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </div>
  )
}

export default GoogleMaps
