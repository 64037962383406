import React from 'react'
import Zoom from 'react-reveal/Zoom'
import { Col, Container, Row } from 'react-bootstrap'

import Title from 'components/Title'
import Hexagon from 'components/Hexagon'

// data
import { coreActivities } from 'data/coreActivities'

const CoreActivities = () => {
  return (
    <Container id='aktiviti-perniagaan'>
      <Title text='Aktiviti Perniagaan Kumpulan' />
      <Row className='pb-5'>
        {coreActivities?.map((data, index) => (
          <Col md={4} className='d-flex justify-content-center'>
            <Zoom delay={data.delay}>
              <Hexagon
                no={`0${index + 1}`}
                icon={data.icon}
                title={data.title}
                text={data.text}
              />
            </Zoom>
          </Col>
        ))}
      </Row>
      <div className='d-flex justify-content-center mb-5'>
        <hr class='border  opacity-100' style={{ width: '50rem' }} />
      </div>
    </Container>
  )
}

export default CoreActivities
