import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap'

import Title from 'components/Title/index'
import NewsCard from 'components/NewsCard/index'

// data
import { news } from '../../data/news'

const NewsMedia = () => {
  return (
    <Container id='berita'>
      <Title text={`Berita & Media`} />
      <Row className='pb-5'>
        <Col>
          {news?.map((data) => {
            console.log('news', data)
            return (
              <Link to={data.link} style={{ textDecoration: 'unset' }}>
                <Fade bottom>
                  <NewsCard
                    image={data.image}
                    title={data.title}
                    date={data.date}
                    desc={data.desc}
                  />
                </Fade>
              </Link>
            )
          })}
        </Col>
      </Row>
      <div className='d-flex justify-content-center mb-5'>
        <hr class='border  opacity-100' style={{ width: '50rem' }} />
      </div>
    </Container>
  )
}

export default NewsMedia
