import React from 'react'

import Card from 'react-bootstrap/Card'

// import './style.scss'

function UserCard({ image, name, title, text }) {
  return (
    <div className='userCard'>
      <Card style={{ width: '13rem' }}>
        <Card.Img variant='top' src={image} />
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Text className='title'>{title}</Card.Text>
          <Card.Text>{text}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

export default UserCard
