import React from 'react'
import { ParallaxBanner } from 'react-scroll-parallax'
import { Col, Container, Row } from 'react-bootstrap'

import Title from 'components/Title/index'

import './style.scss'

//'https://source.unsplash.com/random'
const Careers = () => {
  return (
    <Container>
      <Title text='Kerjaya Pahang Corp' />
      <ParallaxBanner
        layers={[
          {
            image:
              'https://www.ecerdc.com.my/wp-content/uploads/2020/03/SULTAN-ABDULLAH-MUSEUM-MOSQUE-scaled.jpg',
            speed: -20,
          },
        ]}
        className='aspect-[2/1] mb-5'
        style={{ height: '295px' }}
      >
        <div className='careers-wrapper'>
          <div className='text-center p-3 px-5'>
            <h3 className='pb-4'>Kerjaya</h3>
            <p>Tiada kekosongan di buka ketika ini.</p>
          </div>
        </div>
      </ParallaxBanner>
      <div className='d-flex justify-content-center mb-5'>
        <hr class='border  opacity-100' style={{ width: '50rem' }} />
      </div>
    </Container>
  )
}

export default Careers
