import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Fade from 'react-reveal/Fade'

import { Col, Container, Row } from 'react-bootstrap'

import Title from 'components/Title'
import UserCard from 'components/UserCard'

// data
import { boardOfDirectors } from '../../data/boardOfDirectors'

import './style.scss'

const AboutUs = () => {
  return (
    <Container className='about-us'>
      <section id='mengenai' className=' mb-5'>
        <Title text='Mengenai Pahang Corp' />
        <Row className='pb-5'>
          <Col xs={12} md={6}>
            <Fade left>
              <StaticImage
                src='../../images/Oreview.jpg'
                alt='Oreview'
                style={{
                  height: '80vh',
                }}
              />
            </Fade>
          </Col>
          <Col xs={12} md={6}>
            <Fade bottom>
              <Container>
                {/* <h6 className='pb-4 title'>
                  Incorporated On The 21st March 2019
                </h6>
                <h6 className='pb-2'>Wholly Owned (100%) Of Pahang</h6>
                <h6 className='pb-4'>State Government</h6> */}
                <p style={{ marginBottom: '15px' }}>
                  Pahang Corp adalah syarikat milik penuh Kerajaan Negeri Pahang
                  yang ditubuhkan pada 21hb Mac 2019.
                </p>
                <p style={{ marginBottom: '15px' }}>
                  Penubuhan Pahang Corporation adalah untuk mengkaji cadangan
                  pelaburan dari aspek pulangan dan risiko perniagaan serta
                  memantau perlaksanaan aktiviti perniagaan yang diceburi oleh
                  syarikat-syarikat di bawah kumpulan Pahang Corp.
                </p>
                {/* <p style={{ marginBottom: '15px' }}>
                  Sehingga kini, Kerajaan Negeri Pahang mempunyai 40 anak
                  syarikat dalam pelbagai sektor ekonomi. Dengan adanya Pahang
                  Corp, prestasi semua syarikat GLC ini akan dapat dipantau
                  dengan lebih efektif.
                </p> */}
                <p style={{ marginBottom: '15px' }}>
                  Pahang Corp juga akan berfungsi sebagai peneroka sektor baharu
                  yang menjanjikan pulangan pendapatan lebih baik kepada
                  Kerajaan Negeri.
                </p>
                <p style={{ marginBottom: '15px' }}>
                  Sebagai peneraju utama, Pahang Corp menjadi syarikat utama
                  kepada Pahang Corporate Management Services, Koridor Utiliti
                  Pahang, Pahang Water Energy and Resources Sdn Bhd, dan juga
                  PahangGo.
                </p>
              </Container>
            </Fade>
          </Col>
        </Row>
      </section>
      {/* divider */}
      <div className='d-flex justify-content-center mb-5'>
        <hr class='border  opacity-100' style={{ width: '50rem' }} />
      </div>
      <section id='visi-misi' className=' mb-5'>
        <Title text={`Visi & Misi`} />
        <Row className='pb-5'>
          <Col>
            <StaticImage
              src='../../images/VissionMission.jpg' //VissionMission.jpg
              alt='Oreview'
              // height={500}
            />
          </Col>
        </Row>
      </section>
      {/* divider */}
      <div className='d-flex justify-content-center mb-5'>
        <hr class='border  opacity-100' style={{ width: '50rem' }} />
      </div>
      {/* <section id='maklumat-korporat' className=' mb-5'>
        <Title text='Maklumat Korporat' />
        <Row className='pb-5'>
          <Col xs={12} md={5}>
            <Fade bottom>
              <Container>
                <h6 className='pb-4 title'>
                  Incorporated On The 21st March 2019
                </h6>
                <h6 className='pb-2'>Wholly Owned (100%) Of Pahang</h6>
                <h6 className='pb-4'>State Government</h6>
                <p className='pb-4'>
                  Curabitur aliquet quam id dui posuere blandit. Pellentesque in
                  ipsum id orci porta dapibus. Vestibulum ac diam sit amet quam
                  vehicula elementum sed sit amet dui. Donec rutrum congue leo
                  eget malesuada. Sed porttitor lectus nibh. Mauris blandit
                  aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu
                  erat, accumsan id imperdiet et, porttitor at sem. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit. Curabitur aliquet
                  quam id dui posuere blandit. Vestibulum ac diam sit amet quam
                  vehicula elementum sed sit amet dui.
                </p>
                <p className='pb-4'>
                  Curabitur aliquet quam id dui posuere blandit. Pellentesque in
                  ipsum id orci porta dapibus. Vestibulum ac diam sit amet quam
                  vehicula elementum sed sit amet dui. Donec rutrum congue leo
                  eget malesuada. Sed porttitor lectus nibh. Mauris blandit
                  aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu
                  erat, accumsan id imperdiet et, porttitor at sem. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit. Curabitur aliquet
                  quam id dui posuere blandit. Vestibulum ac diam sit amet quam
                  vehicula elementum sed sit amet dui.
                </p>
              </Container>
            </Fade>
          </Col>
          <Col xs={12} md={7} className='d-flex align-items-center'>
            <Fade right delay={30}>
              <div className='pe-2'>
                <StaticImage src='../../images/CS1.jpg' alt='CS1' />
              </div>
            </Fade>
            <Fade right delay={240}>
              <div className='pe-2'>
                <StaticImage src='../../images/CS2.jpg' alt='CS2' />
              </div>
            </Fade>
            <Fade right delay={480}>
              <div className='pe-2'>
                <StaticImage src='../../images/CS3.jpg' alt='CS3' />
              </div>
            </Fade>
          </Col>
        </Row>
      </section> */}
      {/* <div className='d-flex justify-content-center mb-5'>
        <hr class='border  opacity-100' style={{ width: '50rem' }} />
      </div> */}
      <section id='lembaga-pengarah' className=' mb-5'>
        <Title text='Lembaga Pengarah Pahang Corp' />
        <Row className='pb-5'>
          {boardOfDirectors?.map((data) => (
            <Col className='team-wrapper'>
              {/* <Zoom delay={data.delay}> */}
              <UserCard
                image={data.image}
                name={data.name}
                title={data.title}
                text={data.text}
              />
              {/* </Zoom> */}
            </Col>
          ))}
        </Row>
      </section>
      <div className='d-flex justify-content-center mb-5'>
        <hr class='border  opacity-100' style={{ width: '50rem' }} />
      </div>
    </Container>
  )
}

export default AboutUs
