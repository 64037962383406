import React from 'react'

import { ParallaxBanner } from 'react-scroll-parallax'
import Fade from 'react-reveal/Fade'
import { Button } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Keyboard, Pagination } from 'swiper'

import './style.scss'

const Banner = () => {
  return (
    <div className='banner mb-5'>
      <Swiper
        modules={[Keyboard, Pagination]}
        speed={650}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        keyboard={{
          enabled: true,
        }}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
      >
        <SwiperSlide>
          <ParallaxBanner
            layers={[
              {
                children: (
                  <StaticImage
                    src='../../images/Group573.jpg'
                    alt='Banner1'
                    objectFit='unset'
                    style={{ width: '100%', height: '100%' }}
                  />
                ),
                speed: -20,
                scale: [1, 1.2],
              },
            ]}
            className='aspect-[2/1]'
            style={{ width: '100%', height: '100vh' }}
          ></ParallaxBanner>
        </SwiperSlide>
        <SwiperSlide>
          <ParallaxBanner
            layers={[
              {
                children: (
                  <img
                    src='https://www.ecerdc.com.my/wp-content/uploads/2020/03/KUALA-GANDAH-scaled.jpg'
                    alt='Banner2'
                    objectFit='unset'
                    style={{ width: '100%', height: '100%' }}
                  />
                ),
                speed: -20,
                scale: [1, 1.2],
              },
            ]}
            className='aspect-[2/1]'
            style={{ width: '100%', height: '100vh' }}
          ></ParallaxBanner>
        </SwiperSlide>
        <SwiperSlide>
          <ParallaxBanner
            layers={[
              {
                children: (
                  <img
                    src='https://uploads.tapatalk-cdn.com/20200228/e29fc43fb1e32bd260e18daa086b920a.jpg'
                    alt='Banner3'
                    objectFit='unset'
                    style={{ width: '100%', height: '100%' }}
                  />
                ),
                speed: -20,
                scale: [1, 1.2],
              },
            ]}
            className='aspect-[2/1]'
            style={{ width: '100%', height: '100vh' }}
          ></ParallaxBanner>
        </SwiperSlide>
        <Fade bottom>
          <div className='heading-wrapper ps-5 text-white'>
            <h1 className='pb-3'>KUMPULAN PAHANG CORPORATION</h1>
            {/* <h6 className='sub-heading pb-5'>
              Sebuah syarikat milik Kerajaan Negeri Pahang untuk mengadakan
              suatu badan komersil khas milik kerajaan
            </h6>
            <Button
              style={{
                borderRadius: 'unset',
                fontWeight: 300,
                padding: '13px 40px',
                fontSize: '18px',
              }}
            >
              Scroll Down
            </Button> */}
          </div>
        </Fade>
      </Swiper>
    </div>
  )
}

export default Banner
