import React from 'react'
import Card from 'react-bootstrap/Card'

import './style.scss'
// https://picsum.photos/500/230'

const NewsCard = ({ image, title, date, desc }) => {
  return (
    <div class='news-card'>
      <Card className='p-3'>
        <div class='card-img-body'>
          <Card.Img src={image} alt='Card image cap' />
        </div>
        <Card.Body style={{ color: 'black' }}>
          <Card.Title className='pb-4'>{title}</Card.Title>
          <Card.Text className='pb-4 title'>{date}</Card.Text>
          <Card.Text style={{ width: '95%', opacity: '0,7' }}>{desc}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

export default NewsCard
